import { defineAsyncComponent } from 'vue';

// system
async function getSystemLookups() {
    const [OOrgUnitsLookup, OPersonsLookup] = await Promise.all([
        import('./system.OrgUnitsLookup.vue'),
        import('./system.PersonsLookup.vue')
    ]);
    
    return { OPersonsLookup, OOrgUnitsLookup }
}

export const OPersonsLookup = defineAsyncComponent(() => getSystemLookups().then(l => l.OPersonsLookup));
export const OOrgUnitsLookup = defineAsyncComponent(() => getSystemLookups().then(l => l.OOrgUnitsLookup));

// export { default as OPersonsLookup } from './system.PersonsLookup.vue';
// export { default as OOrgUnitsLookup } from './system.OrgUnitsLookup.vue';

// complete
async function getCompleteLookups() {
    const { default: OActivitiesLookup } = await import('./complete.ActivitiesLookup.vue');
    const { default: OActivityTypesLookup } = await import('./complete.ActivityTypesLookup.vue');
    const { default: OHandoverTypesLookup } = await import('./complete.HandoverTypesLookup.vue');
    const { default: OConstructionPhasesLookup } = await import('./complete.ConstructionPhasesLookup.vue');
    const { default: OProjectPhasesLookup } = await import('./complete.ProjectPhasesLookup.vue');
    const { default: OCompletionPhasesLookup } = await import('./complete.CompletionPhasesLookup.vue');
    const { default: OSubProjectsLookup } = await import('./complete.SubProjectsLookup.vue');
    const { default: OPackagesLookup } = await import('./complete.PackagesLookup.vue');
    const { default: OShutdownsLookup } = await import('./complete.ShutdownsLookup.vue');
    const { default: OClassificationCodesLookup } = await import('./complete.ClassificationCodesLookup.vue');
    const { default: OInterfacesLookup } = await import('./complete.InterfacesLookup.vue');
    const { default: ODailyLogEntriesLookup } = await import('./complete.DailyLogEntriesLookup.vue');
    const { default: OMilestonesLookup } = await import('./complete.MilestonesLookup.vue');
    const { default: ORespCodesLookup } = await import('./complete.RespCodesLookup.vue');
    const { default: OExpertiseLookup } = await import('./complete.ExpertiseLookup.vue');
    const { default: OExpertiseLevelsLookup } = await import('./complete.ExpertiseLevelsLookup.vue');
    const { default: OCertificationLevelsLookup } = await import('./complete.CertificationLevelsLookup.vue');
    
    return { OActivitiesLookup, OActivityTypesLookup, OHandoverTypesLookup, OConstructionPhasesLookup,
    OProjectPhasesLookup, OCompletionPhasesLookup, OSubProjectsLookup, OPackagesLookup, OShutdownsLookup,
    OClassificationCodesLookup, OInterfacesLookup, ODailyLogEntriesLookup, OMilestonesLookup, ORespCodesLookup, OExpertiseLookup, OExpertiseLevelsLookup, OCertificationLevelsLookup }
}
export const OActivitiesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OActivitiesLookup));
export const OActivityTypesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OActivityTypesLookup));
export const OHandoverTypesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OHandoverTypesLookup));
export const OConstructionPhasesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OConstructionPhasesLookup));
export const OProjectPhasesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OProjectPhasesLookup));
export const OCompletionPhasesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OCompletionPhasesLookup));
export const OSubProjectsLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OSubProjectsLookup));
export const OPackagesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OPackagesLookup));
export const OShutdownsLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OShutdownsLookup));
export const OClassificationCodesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OClassificationCodesLookup));
export const OInterfacesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OInterfacesLookup));
export const ODailyLogEntriesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.ODailyLogEntriesLookup));
export const OMilestonesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OMilestonesLookup));
export const ORespCodesLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.ORespCodesLookup));
export const OExpertiseLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OExpertiseLookup));
export const OExpertiseLevelsLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OExpertiseLevelsLookup));
export const OCertificationLevelsLookup = defineAsyncComponent(() => getCompleteLookups().then(l => l.OCertificationLevelsLookup));

// export { default as OActivitiesLookup } from './complete.ActivitiesLookup.vue';
// export { default as OActivityTypesLookup } from './complete.ActivityTypesLookup.vue';
// export { default as OHandoverTypesLookup } from './complete.HandoverTypesLookup.vue';
// export { default as OConstructionPhasesLookup } from './complete.ConstructionPhasesLookup.vue';
// export { default as OProjectPhasesLookup } from './complete.ProjectPhasesLookup.vue';
// export { default as OCompletionPhasesLookup } from './complete.CompletionPhasesLookup.vue';
// export { default as OSubProjectsLookup } from './complete.SubProjectsLookup.vue';
// export { default as OPackagesLookup } from './complete.PackagesLookup.vue';
// export { default as OShutdownsLookup } from './complete.ShutdownsLookup.vue';
// export { default as OClassificationCodesLookup } from './complete.ClassificationCodesLookup.vue'
// export { default as OInterfacesLookup } from './complete.InterfacesLookup.vue';
// export { default as ODailyLogEntriesLookup } from './complete.DailyLogEntriesLookup.vue';
// export { default as OMilestonesLookup } from './complete.MilestonesLookup.vue';

// check
async function getCheckLookups() {
    const { default: OChecklistsLookup } = await import('./check.ChecklistsLookup.vue')
    return { OChecklistsLookup }
}
export const OChecklistsLookup = defineAsyncComponent(() => getCheckLookups().then(l => l.OChecklistsLookup));
// export { default as OChecklistsLookup } from './check.ChecklistsLookup.vue';

// assets 
async function getAssetsLookups() {
    const { default: OMainAreasLookup } = await import('./assets.MainAreasLookup.vue');
    const { default: ODisciplinesLookup } = await import('./assets.DisciplinesLookup.vue');
    const { default: OObjectsLookup } = await import('./assets.ObjectsLookup.vue');
    const { default: OAssetConditionLookup } = await import('./assets.AssetConditionLookup.vue');
    const { default: OProductsLookup } = await import('./assets.ProductsLookup.vue');
    const { default: OInstallationStatusesLookup } = await import('./assets.InstallationStatusesLookup.vue');
    const { default: OFunctionCodeLookup } = await import('./assets.FunctionCodeLookup.vue');
    const { default: OComponentsLookup } = await import('./assets.ComponentsLookup.vue');
    const { default: OObjectTypesLookup } = await import('./assets.ObjectTypesLookup.vue');
    const { default: OPreservationLocationLookup } = await import('./assets.PreservationLocationLookup.vue');
    const { default: OMetaObjectLookup } = await import('./assets.MetaObjectLookup.vue');

    return {
        OMainAreasLookup, ODisciplinesLookup, OObjectsLookup, OAssetConditionLookup, OProductsLookup,
        OInstallationStatusesLookup, OFunctionCodeLookup, OComponentsLookup, OObjectTypesLookup, OPreservationLocationLookup, OMetaObjectLookup
    };
}
export const OMainAreasLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.OMainAreasLookup));
export const ODisciplinesLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.ODisciplinesLookup));
export const OObjectsLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.OObjectsLookup));
export const OAssetConditionLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.OAssetConditionLookup));
export const OProductsLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.OProductsLookup));
export const OInstallationStatusesLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.OInstallationStatusesLookup));
export const OFunctionCodeLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.OFunctionCodeLookup));
export const OComponentsLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.OComponentsLookup));
export const OObjectTypesLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.OObjectTypesLookup));
export const OPreservationLocationLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.OPreservationLocationLookup));
export const OMetaObjectLookup = defineAsyncComponent(() => getAssetsLookups().then(l => l.OMetaObjectLookup));
// export { default as OMainAreasLookup } from './assets.MainAreasLookup.vue';
// export { default as ODisciplinesLookup } from './assets.DisciplinesLookup.vue';
// export { default as OObjectsLookup } from './assets.ObjectsLookup.vue';
// export { default as OAssetConditionLookup } from './assets.AssetConditionLookup.vue';
// export { default as OProductsLookup } from './assets.ProductsLookup.vue';
// export { default as OInstallationStatusesLookup } from './assets.InstallationStatusesLookup.vue';
// export { default as OFunctionCodeLookup } from './assets.FunctionCodeLookup.vue';
// export { default as OComponentsLookup } from './assets.ComponentsLookup.vue';
// export { default as OObjectTypesLookup } from './assets.ObjectTypesLookup.vue';
// export { default as OPreservationLocationLookup } from './assets.PreservationLocationLookup.vue';

// cost
async function getCostLookups() {
    const { default: OWBSLookup } = await import('./cost.WBSLookup.vue')
    return { OWBSLookup }
}
export const OWBSLookup = defineAsyncComponent(() => getCostLookups().then(l => l.OWBSLookup));
// export { default as OWBSLookup } from './cost.WBSLookup.vue';

// o365
async function getO365Lookups() {
    const { default: ORolesLookup } = await import('./o365.RolesLookup.vue')
    return { ORolesLookup }
}
export const ORolesLookup = defineAsyncComponent(() => getO365Lookups().then(l => l.ORolesLookup));
// export { default as ORolesLookup } from './o365.RolesLookup.vue';

// arena
async function getArenaLookups() {
    const { default: ODocumentsLookup } = await import('./arena.DocumentsLookup.vue')
    const { default: ODocumentLookup } = await import('./arena.DocumentLookup.vue')
    return { ODocumentsLookup, ODocumentLookup }
}
export const ODocumentsLookup = defineAsyncComponent(() => getArenaLookups().then(l => l.ODocumentsLookup));
export const ODocumentLookup = defineAsyncComponent(() => getArenaLookups().then(l => l.ODocumentLookup));

// export { default as ODocumentsLookup } from './arena.DocumentsLookup.vue';

// scope
async function getScopeLookups() {
    const { default: OWorkflowsLookup } = await import('./scope.WorkflowsLookup.vue')
    const { default: OScopeProcessesLookup } = await import('./scope.ProcessesLookup.vue')
    const { default: OScopeProcessesTreeLookup } = await import('./scope.ProcessLookupTree.vue');
    const { default: OScopeRespCodesLookup } = await import('./scope.RespCodesLookup.vue');
    const { default: OScopeItemsLookup } = await import('./scope.ScopeItemsLookup.vue');
    return { OWorkflowsLookup, OScopeProcessesLookup, OScopeProcessesTreeLookup, OScopeRespCodesLookup, OScopeItemsLookup }
}
export const OWorkflowsLookup = defineAsyncComponent(() => getScopeLookups().then(l => l.OWorkflowsLookup));
export const OScopeProcessesLookup = defineAsyncComponent(() => getScopeLookups().then(l => l.OScopeProcessesLookup));
export const OScopeProcessesTreeLookup = defineAsyncComponent(() => getScopeLookups().then(l => l.OScopeProcessesTreeLookup));
export const OScopeItemsLookup = defineAsyncComponent(() => getScopeLookups().then(l => l.OScopeItemsLookup));
export const OScopeRespCodesLookup = defineAsyncComponent(() => getScopeLookups().then(l => l.OScopeRespCodesLookup));
// export { default as OWorkflowsLookup } from './scope.WorkflowsLookup.vue';

// riskManager 
async function getRiskLookups() {
    const { default: ORisksLookup } = await import('./riskManager.RisksLookup.vue')
    return { ORisksLookup }
}
export const ORisksLookup = defineAsyncComponent(() => getRiskLookups().then(l => l.ORisksLookup));
// export { default as ORisksLookup } from './riskManager.RisksLookup.vue';

// incident
async function getIncidentLookups() {
    const { default: OIncidentsLookup } = await import('./incident.IncidentsLookup.vue')
    return { OIncidentsLookup }
}
export const OIncidentsLookup = defineAsyncComponent(() => getIncidentLookups().then(l => l.OIncidentsLookup));
// export { default as OIncidentsLookup } from './incident.IncidentsLookup.vue';

// resources
async function getResourceLookups() {
    const { default: OResourcesLookup } = await import('./resource.ResourcesLookup.vue')
    return { OResourcesLookup }
}
export const OResourcesLookup = defineAsyncComponent(() => getResourceLookups().then(l => l.OResourcesLookup));
// export { default as OResourcesLookup } from './resource.ResourcesLookup.vue';

// shared
async function getSharedLookups() {
    const { default: OFoldersLookup } = await import('./shared.FoldersLookup.vue')
    const { default: OFolderDocumentsLookup } = await import('./shared.FolderDocumentsLookup.vue')
    return { OFoldersLookup, OFolderDocumentsLookup }
}
export const OFoldersLookup = defineAsyncComponent(() => getSharedLookups().then(l => l.OFoldersLookup));
export const OFolderDocumentsLookup = defineAsyncComponent(() => getSharedLookups().then(l => l.OFolderDocumentsLookup));
// export { default as OFoldersLookup } from './shared.FoldersLookup.vue';
// export { default as OFolderDocumentsLookup } from './shared.FolderDocumentsLookup.vue';

// quality
async function getQualityLookups() {
    const { default: OProductFeaturesLookup } = await import('./quality.ProductFeaturesLookup.vue')
    const { default: OLessonsLearnedLookup } = await import('./quality.LessonsLearnedLookup.vue')
    const { default: OFollowUpItemsLookup } = await import('./quality.FollowUpItemsLookup.vue')
    return { OProductFeaturesLookup, OLessonsLearnedLookup, OFollowUpItemsLookup }
}
export const OProductFeaturesLookup = defineAsyncComponent(() => getQualityLookups().then(l => l.OProductFeaturesLookup));
export const OLessonsLearnedLookup = defineAsyncComponent(() => getQualityLookups().then(l => l.OLessonsLearnedLookup));
export const OFollowUpItemsLookup = defineAsyncComponent(() => getQualityLookups().then(l => l.OFollowUpItemsLookup));
// export { default as OProductFeaturesLookup } from './quality.ProductFeaturesLookup.vue';
// export { default as OLessonsLearnedLookup } from './quality.LessonsLearnedLookup.vue';
// export { default as OFollowUpItemsLookup } from './quality.FollowUpItemsLookup.vue';
